
import styled from 'styled-components';

export const FooterContainer = styled.div`

width: 100%;
display: flex;
color: white;
text-align: center;
justify-content: center
align-themes: center;

`;

export const CopyrightContainer = styled.div`
height: 100%;
align-items: center;
margin: auto;
`;


