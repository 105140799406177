import React, { FC } from 'react';

import MenuItems from '../menu/menu.component';

import { DisplayReducer } from '../../props/propTypes';

const Burger: FC<DisplayReducer> = ({selectedFontFamily, collection}) =>  (
    <MenuItems collection={collection} 
      right pageWrapId={'page-wrap'} 
      outerContainerId={'App'} 
      selectedFontFamily={selectedFontFamily} />
  )


export default Burger