import { combineReducers } from 'redux';

import displayReducer from './display/display.reducer';
import eventReducer from './event/event.reducer';


const rootReducer = combineReducers({
  displayOptions: displayReducer,
  eventInfo: eventReducer
});

export default rootReducer;

