//import "./wydr";

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';

import { fetchDisplayData } from './redux/display/display.reducer';
import { store } from './redux/store';
import ViewportProvider from './context/viewPointProvider.component'

import App from './App';

store.dispatch(fetchDisplayData);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
          <ViewportProvider>
            <App />
          </ViewportProvider>
        </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);