import styled from 'styled-components';

export const HeaderContainer = styled.div`
height: 65px;
max-width: 100%;
`;

export const LogoContainer = styled.div`
position: relative;
@media screen and (max-width: 400px) {
    img {
        width: 15rem;
    }
}
@media screen and (max-width: 350px) {
    img {
        width: 12rem;
    }
}
`;

