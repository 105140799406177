import { DisplayType, OptionType, SelectedOptions } from "../../props/propTypes";

export const isEmpty = (obj: any): boolean => {
    for(var i in obj) return false; 
    return true;
}

export const GetSelectedOptionFromLoad= (collection: any, data: any): OptionType | null => {
    if (isEmpty(collection))
        return null;

    if (!data.selectedOption)
        return null;

    const displayType: DisplayType = collection[data.key];

    let foundOption: OptionType | undefined = undefined;

    if (displayType && displayType.options)
        foundOption = displayType.options.find(dt => dt.value === data.selectedOption.value)
    
    return foundOption ?? null;
}

export const findSelectedValueInCollectionOption = (optionType: string, collection: any) : string => {
    const options: Array<OptionType> = collection[optionType]?.options;
    let selectedValue: string  = '';

    if (options !== undefined){
        const foundValue: OptionType | undefined = options.find((ff: OptionType) => ff.selected);
        if (foundValue !== undefined)
            selectedValue = foundValue.value;
    }

    return selectedValue;
}

export const LoadLanguageCollection = (collection: any, data: any): any => {
    if (collection[data.key])
        collection[data.key].options = data.options;
    return collection;
}

export const SetAllOfOptionToTrueTheRestFalse = (collection: any, data: any): any =>{

    if (!data.selectedOption)
        return collection;

    const displayType: DisplayType = collection[data.key];
    displayType.options.forEach(o => o.selected = (o.value === data.selectedOption.value))

    if (data.key === 'foreColor' || data.key === 'backColor'){
        SetDisabledValueForColorOptions(collection, data.key);
    }

    return collection;
}

const SetDisabledValueForColorOptions = (collection: any, currentColor: string): void =>{
    
    if (currentColor !== 'foreColor' && currentColor !== 'backColor')
        return;

    const oppositeColor = currentColor === 'foreColor' ? 'backColor' : 'foreColor'
    const selectedValue = collection[currentColor].options.find((o: OptionType) => o.selected)
    if (selectedValue){
        var oppositeOptions: Array<OptionType> = collection[oppositeColor].options;
        oppositeOptions.forEach(o => o.isDisabled = o.value === selectedValue.value)
    }
}

export const getSelectedOptionFromCollection = (collection: any, optionType: string, selectedOption: string): OptionType | null => {
    const selectedValue = collection[optionType].options.find((o: OptionType) => o.value === selectedOption);

    return selectedValue;
}

export const setSelectedValue = (data: OptionType | null) => {
    if (data === null)
        return;

    const optionType = data as OptionType;

    if (optionType.parent?.playerPropertyName === 'language')
        return;

    const optionChangeText = `{"${optionType.parent?.playerPropertyName}": "${optionType.value}"}`;
    var optionChange = JSON.parse(optionChangeText); 
    
    postStreamText(optionChange);


 };

export const postStreamText = (options: any) => {
    if ((document as any).streamTextWindow)
        (document as any).streamTextWindow.postMessage(JSON.stringify({ operation: "update-options", options: options }), "*");
}

export const setDisabledOptionBasedOnCurrentSelection = (collection: any, data: OptionType | null) =>{
    if (data === null)
        return collection
    
    if (data.parent){
        const displayType: DisplayType  = collection[data.parent.playerPropertyName];
        displayType.options.forEach(o => o.selected = o.value === data.value);
        SetDisabledValueForColorOptions(collection, data.parent.playerPropertyName)
    }
        
    return collection;
}


export const setAutoScroll = () => {
    const checkBox : any=document.getElementById("autoScroll");
    sendAutoScrollSetToStreamText(checkBox.checked);
}

export const sendAutoScrollSetToStreamText = (scroll: boolean) => {
    const optionAutoScrollOn = `{"scroll": ${scroll}}`;
    const optionChange = JSON.parse(optionAutoScrollOn);

    postStreamText(optionChange); 
}

export const sendDisplayIndecatorToStreamText = (indicator: boolean) => {
    const optionAutoScrollOn = `{"indicator": ${indicator}}`;
    const optionChange = JSON.parse(optionAutoScrollOn);

    postStreamText(optionChange); 
}

export const setStoredSelectedValue = (curSelectedValues : SelectedOptions, data: OptionType | null) : SelectedOptions => {
    if (data === null)
        return curSelectedValues;

    if (data.parent){
        const parent = data.parent;
        const {options, ...otherProps} = parent;
        
         curSelectedValues[parent.playerPropertyName] = {value: data.value, ...otherProps};
        return curSelectedValues;
    }
    return curSelectedValues;
}