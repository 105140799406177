import { FunctionComponent } from 'react';
import {ExternalLink } from 'react-external-link';
import { CopyrightContainer, FooterContainer} from './footer.styles'

const Footer : FunctionComponent = () => {

    return (<FooterContainer>
        <CopyrightContainer>
            Captions by <ExternalLink href="http://www.3playmedia.com" style={{color : "white"}}>3Play Media</ExternalLink></CopyrightContainer>
    </FooterContainer>)
}

export default Footer;