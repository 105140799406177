import styled,{css} from 'styled-components';

const divMargin = css`

`;

export const SelectOptionContainer = styled.div`
font-size: 10pt;
`;

export const SelectOptionLabelContainer = styled.div`
color: white;
${divMargin}
`;

export const SelectOptionSelectContainer = styled.div`
color: black;
${divMargin}
`;

