import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';

import PlayerPage from './pages/playerPage/playerPage.component'

import {GlobalStyles} from './styles/globalStyles';
import './App.css';

const mainPage = (props: any) => (
      <>
        <GlobalStyles {...props} />
        <PlayerPage  {...props} />
      </>
)

const App : FunctionComponent<any> = ({dispatch}) =>{
  
  return (
    <div className="App">
      <Route path='/:event?/:showTest?' component={mainPage} />
    </div>
  )
}

export default App;