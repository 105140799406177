/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect } from 'react';

import { TitleTextContainer, TitlePageContainer, AutoScrollContainer, FullScreenContainer, 
    FullScreenContainerIcon, AutoScrollContainerIcon} from './title.styles';

import { setAutoScroll } from '../../redux/display/display.util';
import { setSessionPageScroll } from '../../redux/display/display.actions'; 
import { connect } from 'react-redux';

const makeFullScreen = (setScroll:any) => {
    var frame : HTMLIFrameElement | null = document.getElementById('streamTextIFrame') as HTMLIFrameElement;
    launchFullscreen(frame);
    function launchFullscreen(element:any) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        }
    };
}

export interface TitleProps {
    title: string;
    onClick?: any;
    setPageScroll?: any;
}

const Title : FunctionComponent<TitleProps> = ({title, onClick, setPageScroll}) => {

    const [checked ] = React.useState(true);

    const handleFullScreen = () =>{
        if (document.fullscreenElement)
            setPageScroll(false);
        else
            setPageScroll(true);
    }

    useEffect(() =>{
        document.addEventListener('fullscreenchange', handleFullScreen);
         return () => { document.removeEventListener('fullscreenchange', handleFullScreen)}
    })

    
    return (
        
    <TitlePageContainer>
        <TitleTextContainer>{title}</TitleTextContainer>
        <div style={{width: '100%'}}>
            <AutoScrollContainer>Auto Scroll</AutoScrollContainer>
                <AutoScrollContainerIcon>            
                    <input type="checkbox" id="autoScroll" defaultChecked={checked} onClick={(setAutoScroll)} ></input>              
            </AutoScrollContainerIcon>  
            <FullScreenContainer>Full Screen</FullScreenContainer>

            <FullScreenContainerIcon>       
                <a href="#">
                    <span onClick={() => makeFullScreen(setPageScroll)} id="fullscreen" className="glyphicon glyphicon-fullscreen"></span>
                </a>             
            </FullScreenContainerIcon>
        </div>
    </TitlePageContainer>
    )   
 }

 const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    setPageScroll: (pageScroll: boolean) => dispatch(setSessionPageScroll(pageScroll))
  });
  

export default connect(null, mapDispatchToProps)(Title);