import styled from 'styled-components';

export const TitlePageContainer = styled.div`
font-size: 1.2em;
font-weight: bold;
color: black;
background-color: white;
border-bottom: 1px;
border-color: black;
border-style: solid;
display: flex;
`;

export const TitleTextContainer = styled.div`
width: 50%;
padding-left: 5px;
@media screen and (max-width: 400px) {
    text-align: center;
}
@media screen and (min-width: 800px) {
    width: 75%;
}
`;

export const AutoScrollContainer = styled.div`
margin: auto;
position: absolute;
right: 150px;
margin-top: 3px;
`; 

export const FullScreenContainer = styled.div`
margin: auto;
position: absolute;
right: 32px;
margin-top: 3px;
`;

export const FullScreenContainerIcon = styled.div`
margin: auto;
position: absolute;
right: 10px;
margin-top: 4px;
`;

export const AutoScrollContainerIcon = styled.div`
margin: auto;
position: absolute;
right: 130px;
margin-top: 4px;
`;

