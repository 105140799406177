import { OptionType } from "../../props/propTypes";
import chroma from 'chroma-js';

export const dot = (color = '#ccc'): any => ({
    alignItems: 'center',
    display: 'flex',
    
    ':before': {
      backgroundColor: color,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
      borderRadius: 10 / 2,
      border: '1px solid black',
    },
  });

export const customStyles = (fontFamily: string) => ({
    control: (styles: any) => ({
      ...styles,
      background: 'white',
      display: 'flex',
      width: 'auto',
      fontFamily: fontFamily,
    }),
    menuPortal: (styles: any) => ({
      ...styles,
      zIndex: 9999,
    }),
    menu: (styles: any) => ({
      ...styles,
      fontFamily: fontFamily,
      zIndex: 9999,
    }),
    option: (
      styles: any, { data }: { data: OptionType } ) => {
  
      if(data.value.startsWith('#')){

        const color = chroma(data.value);      
      
        return {...styles, ...dot(data.value),      
          ':active': {
            ...styles[':active'],
            color: color.alpha(0.3).css(),
          },
        };
    }
    else
      return { ...styles }
    },

    input: (styles: any) => ({ ...styles,...dot}),
    placeholder: (styles: any) => ({ ...styles, ...dot}),
    singleValue: (styles: any, { data }: { data: any }) => {
      if((data.value as string).startsWith('#'))
        return { ...styles, ...dot(data.value) }
      else return {...styles};
    },
  }
)
    
   
  