import React, { FC } from 'react';
import Slider from "../reactSlider/slider.component"
// import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from "react-icons/io5";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { DisplayReducer } from '../../props/propTypes';

import SelectOption from './selectOption.component';
import { SelectOptionItemContainer } from './selectOptionCollection.styles'

import { DisplayType } from '../../props/propTypes';
    

export interface SelectOptionCollectionProps extends DisplayReducer{
    isBurger: boolean;
    width?: number;
}
const SelectOptionCollection: FC<SelectOptionCollectionProps> = 
    ({collection, selectedFontFamily, isBurger }) =>{
     
        const typesWithOptions = Object.keys(collection).reduce((accum: Array<string>, key: string) => {
          const displayType: DisplayType = collection[key];
          if (displayType === undefined || !displayType.options || displayType.options.length <= 1) 
            return accum;
          
          accum.push(key);
          return accum;
        }, []);

        let displayOrder = typesWithOptions.sort((a: string, b: string) => {
          const testA : DisplayType = collection[a];
          const testB : DisplayType = collection[b];
          if (testA.displayOrder === testB.displayOrder) return 0;
          return (testA.displayOrder > testB.displayOrder) ? 1 : -1
          });

        var sliderSettings = {
            infinite: false,
            slidesToShow: displayOrder.length,
            slidesToScroll: 1,
            initialSlide: 0,
            speed: 500,
            responsive: [
              {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 829,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  initialSlide: 0,
                }
              },
              {
                breakpoint: 685,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 540,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
            ]
          };


        return (
        <div>
            <Slider {...sliderSettings} >
            {
                    displayOrder.map((key, index) => {
                        const found = collection[key].options.find(o => o.selected);
                
                        collection[key].options.forEach( ot => ot.parent = collection[key])
                        return(
                            <SelectOptionItemContainer isBurger={isBurger} key={`SelectOptionItemContainer_${isBurger}_${index}`}>
                                <SelectOption displayName={collection[key].displayName}  
                                    options={collection[key].options} 
                                    index={index} 
                                    key={`${collection[key].id}_${collection[key].playerPropertyName}`}
                                    value={found ? found : null}
                                    isOptionDisabled={(option: any) => option?.isDisabled ?? false}
                                    selectedFontFamily={selectedFontFamily}  />
                            </SelectOptionItemContainer>);
                    })
                }
            </Slider>
        </div>
    );
}

export default SelectOptionCollection;
