import { HtmlSelectChange } from './../../props/propTypes';
import { DisplayType, OptionType, SelectedOptions } from '../../props/propTypes';
import DisplayActionTypes from './display.types';
import { GetSelectedOptionFromLoad, LoadLanguageCollection, 
    SetAllOfOptionToTrueTheRestFalse, setDisabledOptionBasedOnCurrentSelection, 
    setSelectedValue, setStoredSelectedValue,
    findSelectedValueInCollectionOption, getSelectedOptionFromCollection } from './display.util';


const findselectedValues = (serverData: any) : SelectedOptions =>{
    const selected : SelectedOptions = {backColor: null, fontFamily: null, fontSize: null, foreColor: null, language: null};
    
    Object.keys(serverData)
        .forEach((key) => {
            if (Object.keys(serverData[key]).length > 0){
                const {options, ...optionInfo} = serverData[key];
                const found = serverData[key].options.find((o: any) => o.selected) as OptionType;
                if (found)
                    selected[optionInfo.playerPropertyName] = {value: found.value, ...optionInfo}
            }
                
        }); 
    return selected;
}

export interface DisplayState{
    optionsLoaded: boolean;
    sessionPageScroll: boolean;
    selectedFontFamily: string | undefined;
    selectedOptions: SelectedOptions;
    collection: any;
    currentLanguage: string;
}

const INITIAL_STATE : DisplayState = {
    collection: {},
    selectedOptions: {backColor: null, fontFamily: null, fontSize: null, foreColor: null, language: null},
    selectedFontFamily: undefined,
    optionsLoaded: false,
    sessionPageScroll: true,
    currentLanguage: '',
};

export interface DisplayAction {
    type : string;
    payload: any;
}

const displayReducer = (state = INITIAL_STATE, action : DisplayAction) : DisplayState => {
    switch (action.type){
        case DisplayActionTypes.CHANGE_SESSION_SCROLLING:
            return {
                ...state,
                sessionPageScroll: action.payload,
            };
        case DisplayActionTypes.LOAD_LANGUAGE_OPTIONS:
            const collectionWithUpdatedLanguage = LoadLanguageCollection(state.collection, action.payload)
            const selectedFontFamily = findSelectedValueInCollectionOption('fontFamily', collectionWithUpdatedLanguage);
            return {
                ...state,
                collection: collectionWithUpdatedLanguage,
                selectedFontFamily: selectedFontFamily,
            };
        case DisplayActionTypes.CHANGE_SELECTED_FROM_EVENTLOAD:
            const foundOption = GetSelectedOptionFromLoad(state.collection, action.payload);

            if (foundOption === null){
                return {
                    ...state,
                }
            }
            
            const collectionFromEventLoad = SetAllOfOptionToTrueTheRestFalse(state.collection, action.payload);
            const selectedFontFamilyFromEventLoad = findSelectedValueInCollectionOption('fontFamily', collectionFromEventLoad);
            const selectedLanguageFromEventLoad = findSelectedValueInCollectionOption('language', state.collection);
            
            return {
                ...state,
                currentLanguage: selectedLanguageFromEventLoad,
                collection: collectionFromEventLoad,
                selectedOptions: setStoredSelectedValue(state.selectedOptions, foundOption),
                selectedFontFamily: selectedFontFamilyFromEventLoad,
            }
        case DisplayActionTypes.CHANGE_SELECTED_VALUE_HTMLSELECT:
            var selectChange: HtmlSelectChange = action.payload;
            const htmlSelectedOption: OptionType | null = getSelectedOptionFromCollection(state.collection, selectChange.optionFamily, selectChange.selectedOption);
            setSelectedValue(htmlSelectedOption);
            const collectionFromHtmlSelectedValue = setDisabledOptionBasedOnCurrentSelection(state.collection, htmlSelectedOption);
            const selectedLanguageFromHtmlSelectedValue = findSelectedValueInCollectionOption('language', collectionFromHtmlSelectedValue);
            const selectedFontFamilyFromHtmlSelectedValue = findSelectedValueInCollectionOption('fontFamily', collectionFromHtmlSelectedValue);

            return {
                ...state,
                currentLanguage: selectedLanguageFromHtmlSelectedValue,
                selectedOptions: setStoredSelectedValue(state.selectedOptions, htmlSelectedOption),
                collection: collectionFromHtmlSelectedValue,
                selectedFontFamily: selectedFontFamilyFromHtmlSelectedValue,
            };
        case DisplayActionTypes.CHANGE_SELECTED_VALUE:
            setSelectedValue(action.payload);
            const collectionFromSelectedValue = setDisabledOptionBasedOnCurrentSelection(state.collection, action.payload);
            const selectedLanguageFromSelectedValue = findSelectedValueInCollectionOption('language', collectionFromSelectedValue);
            const selectedFontFamilyFromSelectedValue = findSelectedValueInCollectionOption('fontFamily', collectionFromSelectedValue);

            return {
                ...state,
                currentLanguage: selectedLanguageFromSelectedValue,
                selectedOptions: setStoredSelectedValue(state.selectedOptions, action.payload),
                collection: collectionFromSelectedValue,
                selectedFontFamily: selectedFontFamilyFromSelectedValue,
            };
        case DisplayActionTypes.LOAD_DISPLAY_OPTIONS:
            const transformedServerData = action.payload.reduce((accum: any, serverData: DisplayType) => {
                var propName = serverData.playerPropertyName ==='textColor'
                        ? 'foreColor'
                        : serverData.playerPropertyName;
                accum[propName] = serverData;
                if (propName === 'language' && state.currentLanguage === ''){
                    accum[propName].options = [];
                }
                return accum;
            }, {});
            Object.keys(transformedServerData).forEach(key => {
                    if (key !== 'language'){
                        var options: Array<OptionType> = transformedServerData[key].options;
                        options.forEach(o => o.isDisabled = false);
                    }
                });
            const selectedFontFamilyOnLoad = findSelectedValueInCollectionOption('fontFamily', transformedServerData);
            //const selectedLanguageOnLoad = findSelectedValueInCollectionOption('language', transformedServerData);
            return {
                ...state,
                collection: transformedServerData,
                selectedOptions: findselectedValues(transformedServerData),
                optionsLoaded: true,
                selectedFontFamily: selectedFontFamilyOnLoad,
                //currentLanguage: selectedLanguageOnLoad,
            };
        default:
            return state;
    }
}

export async function fetchDisplayData(dispatch: any, getState: any){
    const apiUrlresponse = await fetch(process.env.REACT_APP_GETAPILOCATIONURL);
    const urlJson = await apiUrlresponse.json();
    
    const request = new Request(`${urlJson.url}/Display`, {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json'})
    });
    
    const response = await fetch(request);
    const json = await response.json()
    dispatch({type: DisplayActionTypes.LOAD_DISPLAY_OPTIONS, payload: json})
}

export default displayReducer;