import { FunctionComponent, memo } from 'react';
import {isMobileOnly} from 'react-device-detect';

import LogoImage from '../../assets/Jetstream 170x75.png'

import Burger from '../burger/burger.component';
import { HeaderContainer, LogoContainer } from './header.styles'
import SelectOptionCollection from '../selectOption/selectOptionCollection.component';

import { DisplayReducer } from '../../props/propTypes';

const Header : FunctionComponent<DisplayReducer> = (props) => { 
    const {collection, match, selectedFontFamily} = props;

    return (<HeaderContainer>
        <LogoContainer>
            <a href='http://www.3playmedia.com' target='_blank' rel='noreferrer'><img src={LogoImage} alt='3Play Media'/></a>
        </LogoContainer>
        <div className="dropDownContainer">
        {
            match.params.event === undefined || Object.keys(collection).length === 0 ?
                null :
            (isMobileOnly ?
                <Burger collection={collection} selectedFontFamily={selectedFontFamily} />
                : 
                <SelectOptionCollection 
                    collection={collection}
                    selectedFontFamily={selectedFontFamily}
                    isBurger={false}
                />
            )

        }
        </div>
            
        
    </HeaderContainer>)
}

let languageCount: number = 0;


const hasOptionsCount = (collection: any): number =>{
    if (Object.keys(collection).length === 0)
        return 0;

    let counter = 0;
    Object.keys(collection).forEach((key: string) => {
        if (collection[key].options.length > 0)
            ++counter;
    })
    return counter;
}

const areStatesEqual = (prevProps : DisplayReducer, newProps: DisplayReducer) => {
    const newOptionCount: number = hasOptionsCount(newProps.collection);
    
    if (newOptionCount === 0)
        return true;

    let langCount: number = 0;

    if (newOptionCount > 0)
        langCount = newProps.collection['language'].options.length;

    const areEqual = (hasOptionsCount(prevProps.collection) === newOptionCount) && 
        (langCount === languageCount) && 
        (prevProps.selectedFontFamily === newProps.selectedFontFamily);

    if (langCount !== languageCount)
        languageCount = langCount;

    return areEqual;
} 



export default memo(Header, areStatesEqual);