const DisplayActionTypes = {
    CHANGE_SELECTED_VALUE:                      'CHANGE_SELECTED_VALUE',
    CHANGE_SELECTED_VALUE_HTMLSELECT:          'CHANGE_SELECTED_VALUE_HtmlSelect',
    CHANGE_SELECTED_FROM_EVENTLOAD:             'CHANGE_SELECTED_FROM_EVENTLOAD',
    LOAD_DISPLAY_OPTIONS:                       'Load_Display_Options',
    LOAD_LANGUAGE_OPTIONS:                      'Load_Language_Options',
    CHANGE_SESSION_SCROLLING:                   'Change_Session_Scrollling',

};

export default DisplayActionTypes;