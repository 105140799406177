import EventActionTypes from "./event.types";

export enum EventLoadStatus {
    'Empty',
    'Loading',
    'Loaded',
    'Error',
    'BadEventName'
}

export interface EventState {
    event: any;
    eventStatus: EventLoadStatus;
    errorMessage: string|undefined;
    eventName: string | undefined;
}

const INITIAL_STATE : EventState = {
    event: {},
    eventName: undefined,
    eventStatus: EventLoadStatus.Empty,
    errorMessage: undefined
};

export interface EventAction {
    type : string;
    payload: any;
};

const eventReducer = (state: EventState = INITIAL_STATE, action : EventAction): EventState=> {
    switch(action.type){
        case EventActionTypes.EVENTINFO_LOADED_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                eventStatus: EventLoadStatus.Error
            };
        case EventActionTypes.EVENTINFO_LOADED_SUCCESS:
            return { 
                ...state,
                event: action.payload,
                eventStatus: EventLoadStatus.Loaded
            };
        case EventActionTypes.LOAD_EVENT_DATA:
            return {
                ...state,
                eventStatus: EventLoadStatus.Loading
            }
        case EventActionTypes.EVENTINFO_EVENT_NOTEXISTS:
            return {
                eventName: action.payload,
                event: {},
                errorMessage: `Event ${action.payload} doesn't exist`,
                eventStatus: EventLoadStatus.BadEventName
            }
        case EventActionTypes.EVENTINFO_SET_EVENTNAME:
            return {
                ...state,
                eventName: action.payload
            }
        default: return state;
    }
};

export default eventReducer;