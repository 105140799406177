import styled from 'styled-components';

export interface PlayerPageContentsStyleProps {
  backgroundColor: string;
}

export const PageContainer = styled.div`
  height: 100vh; /* full viewport height */
  padding: 5px 1px 0 1px;
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
flex: 0 0 auto; /* don't grow or shrink in any case */
`;

export const PlayerPageContents = styled.div<PlayerPageContentsStyleProps>`
  flex: 1; /* fill the remaining space */
  min-height: 0; /* optional */
  overflow: hidden; /* overflow if exceeds available space */
  background: ${p => p.backgroundColor };
`