import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { connect } from 'react-redux';


import {
  SelectOptionContainer,
  SelectOptionLabelContainer,
  SelectOptionSelectContainer,
} from '../selectOption/selectOption.styles';

import { OptionSelectProps, OptionType, iniFrame, HtmlSelectChange  } from '../../props/propTypes';
import { customStyles } from './select.styles';
import { ChangeSelectedItem, ChangeSelectedItemHtmlSelect } from '../../redux/display/display.actions';

interface SelectOptionState {
  currentValue: any
}


class SelectOption extends Component<OptionSelectProps, SelectOptionState> {

  displayOptionType: string;
  isInIFrame: boolean;

  constructor(props: OptionSelectProps){
    super(props);

    this.isInIFrame = iniFrame();
    this.displayOptionType = (props.value as any).parent.playerPropertyName;

    if (this.isInIFrame)
      this.state = {currentValue: (props.value as any).value};
    else
      this.state = {currentValue: props.value};
  }
  
  onchange = (val: any, propType: any, dispatch: any) => {
    var selectedValue: HtmlSelectChange = {optionFamily: propType.playerPropertyName, selectedOption: val.target.value};
    this.setState({currentValue: selectedValue.selectedOption});
    dispatch(selectedValue);
  }

  raectSelectChange = (val: any, dispatch: any) => {
    this.setState({currentValue: val});
    dispatch(val);
  }

  shouldComponentUpdate(newProps: any, newState: any){
    if (!(this.displayOptionType in this.props.selectedOptions) || this.props.selectedOptions[this.displayOptionType] === null){
      return true;
    }

    const shouldUpdateVal: boolean = !(this.state.currentValue.value === newProps.selectedOptions[this.displayOptionType].value);
    return shouldUpdateVal;
  }

  componentDidUpdate(){

    if (!(this.displayOptionType in this.props.selectedOptions) || this.props.selectedOptions[this.displayOptionType] === null)
      return;
    
    if (this.isInIFrame){
      if (this.props.selectedOptions[this.displayOptionType].value !== this.state.currentValue)
        this.setState({currentValue: this.props.selectedOptions[this.displayOptionType].value})
    }
    else{
      const currentSelectedValue = this.props.selectedOptions[this.displayOptionType].value;
      const options: Array<any> = this.props.collection[this.displayOptionType].options;
      const foundOption = options.find(o => o.value === currentSelectedValue);
      if (foundOption !== undefined && foundOption !== this.state.currentValue){
        this.setState({currentValue: foundOption}) 
      }
    }
  }

  render() {
    const { displayName, options, index, setSelectedValue, setSelectedForHtmlSelect, selectedFontFamily, value, ...otherProps } = this.props;

    return (
      <SelectOptionContainer>
        <SelectOptionLabelContainer>
          <label key={`displayLabelFor${displayName}`} style={{ gap: '50px', fontFamily: selectedFontFamily }}>{displayName}: </label>
        </SelectOptionLabelContainer>
        <SelectOptionSelectContainer>
          {
            this.isInIFrame ?
              <select style={{width: '14rem'}} value={this.state.currentValue} key={`htmlSelectFor${displayName}`} onChange={(ele: any) => this.onchange(ele, (options as any)[0].parent, setSelectedForHtmlSelect)}>
                  {options?.map((o: any) => <option key={`htmlOptionOptionId_${o.id}`} value={o.value}>{o.label}</option>)}
              </select>
            : 
            <div style={{width: '14rem'}}>
              <ReactSelect
                {...otherProps}
                styles={customStyles(selectedFontFamily)}
                options={options}
                onChange={(data : any) => this.raectSelectChange(data, setSelectedValue)}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                value={this.state.currentValue}
              />
            </div>
          }          
        </SelectOptionSelectContainer>
      </SelectOptionContainer>
    );
  }
}

const mapStateToProps = (state : any) => ({
  selectedOptions : state.displayOptions.selectedOptions,
  collection: state.displayOptions.collection,
  displayOptions: state.displayOptions,
})

const mapDispatchToProps = (dispatch : any) => ({
  setSelectedValue: (selectedValue : OptionType) => dispatch(ChangeSelectedItem(selectedValue)),
  setSelectedForHtmlSelect: (selectedValue: HtmlSelectChange) => dispatch(ChangeSelectedItemHtmlSelect(selectedValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectOption);
