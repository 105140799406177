import {Props as SelectProps} from 'react-select';
import { Props as BurgerProps } from "react-burger-menu";

export interface ExtendedBurgerProps extends BurgerProps, DisplayReducer{
}

export interface OptionSelectProps extends SelectProps{
    isDiplayNameVisible?: boolean;
    index: number;
    selectedFontFamily: string;
    setSelectedValue: (selectedValue : OptionType) => void;
}

export interface rootReducer {
    displayOptions: DisplayReducer,
  }
  

export interface BaseDisplayType{
    id: number;
    displayName: string;
    playerPropertyName : string;
    playerEventName : string;
    queryStringParamName: string;
    displayOrder: number;
}

export interface SelectedDisplayType extends BaseDisplayType{
    value: string;
}

export interface DisplayType extends BaseDisplayType {
    options: Array<OptionType>;
}

export interface HtmlSelectChange {
    selectedOption: string;
    optionFamily: string;
}

export interface OptionType {
    label: string;
    id: number;
    value: string;
    selected?: boolean;
    isDisabled: boolean;
    parent?: DisplayType;
}

export interface PlayerAppState{
    displayOptions: DisplayReducer;
}

export interface DisplayReducer{
    collection : Record<string, DisplayType>;
    selectedFontFamily?: string;
    match?: any;
    //selectedOptions?: SelectedOptions | null;
}

export interface SelectedOptions {
    [option: string]: SelectedDisplayType | null;
    language: SelectedDisplayType | null;
    foreColor: SelectedDisplayType | null;
    backColor: SelectedDisplayType | null;
    fontSize: SelectedDisplayType | null;
    fontFamily: SelectedDisplayType | null;
}

export const iniFrame = (): boolean => {
    if ( window.location !== window.parent.location )
        return true
    else 
        return false;
}

export const moveArrayItemToNewIndex = (arr: Array<any>, old_index: number, new_index: number): Array<any> => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; 
};
