import React from 'react';

export interface viewPortProviderInterface{
    width: number;
    height: number
}



const viewportContext = React.createContext({});

const ViewportProvider: any = ({ children }: { children: any}) => {

    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

export const useViewport = (): viewPortProviderInterface => {
    const { width, height } = React.useContext(viewportContext) as viewPortProviderInterface;
    return { width, height };
  };


  export default ViewportProvider;