import React, {useEffect, useState, memo} from 'react';
import {connect} from 'react-redux';

import Header from '../../components/header/header.component';
import Title from '../../components/title/title.component'
import Footer from '../../components/footer/footer.component'
import { SpinnerOverlay } from '../../components/with-spinner/with-spinner.styles';

import { PageContainer, FooterContainer,
  PlayerPageContents} from './playerPage.styles'

import { SelectedDisplayType } from '../../props/propTypes';
import { fetchEventInfo } from '../../redux/event/event.actions';
import { EventLoadStatus } from '../../redux/event/event.reducer';
import WithSpinner from '../../components/with-spinner/with-spinner.component';

const sholdDisplayWelcomeMessage = (eventInfo: any): boolean =>{
  var result: boolean = eventInfo?.event?.isWelcomeMessageEnabled && eventInfo?.event?.status === "Scheduled";
  return result;
}


const PlayerPage = (props:any) => {

  const {displayOptions, eventInfo, match, fetchEventInfo }  = props;
  const [ loaded, setLoaded ] = useState<boolean>(false)
  const {selectedOptions, collection, selectedFontFamily} = displayOptions;
  const [ pageScroll, setPageSCroll] = useState<boolean>(true);
  const [ curLanguage, setCurLanguage ] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() =>{
    let timer: any = null;

    if (!match.params.event)
      return;

    if (eventInfo.eventName !== match.params.event && Object.keys(collection).length > 0){
      setLoaded(false);
      fetchEventInfo(match.params.event);
    }
    
    /*
    --These are some debug messages to figure out why the page isn't loading
    console.log(eventInfo.eventStatus);
    console.log(loaded);
    console.log(displayOptions.sessionPageScroll);
    console.log(pageScroll);
    console.log(displayOptions.currentLanguage);
    console.log(curLanguage);
    */
    if ((eventInfo.eventStatus === EventLoadStatus.Loaded 
      && !loaded)
      || displayOptions.sessionPageScroll !== pageScroll
      || displayOptions.currentLanguage !== curLanguage){

      let event = match.params.event;

      if (match.params.showTest)
        event = 'captionmax-demo&words=1';

      if (displayOptions.sessionPageScroll !== pageScroll){
        setPageSCroll(displayOptions.sessionPageScroll);
      }

      if (displayOptions.currentLanguage !== curLanguage)
        setCurLanguage(displayOptions.currentLanguage)

      let url = `https://${process.env.REACT_APP_STREAMTEXTPLAYER}/player/embedded?event=${event}&annotations=0&start=0&footer=0&scroll=${displayOptions.sessionPageScroll}&indicator=${displayOptions.sessionPageScroll}`;

      if (!eventInfo.event.allowCopyContent)
        url = url + '&content-style=user-select:none'
      
      if (selectedOptions)
        Object.keys(selectedOptions).forEach((key) => {
          var displayType : SelectedDisplayType = selectedOptions[key] as SelectedDisplayType
          if (displayType ===  null)
            return;
          url += `&${displayType.queryStringParamName}=${displayType.value.replace("#", "").replace("px", "")}`
        })

      console.log(`The url is ${url}`);
      var iFrame : HTMLIFrameElement | null = document.querySelector("#streamTextIFrame")
      if (iFrame)
        iFrame.src = url;
      
      var frame : HTMLIFrameElement | null = document.getElementById('streamTextIFrame') as HTMLIFrameElement;
      if (frame)
        (document as any).streamTextWindow = frame.contentWindow;

      if (frame && iFrame)
        setLoaded(true)
    }
    
    if (sholdDisplayWelcomeMessage(eventInfo)){
      let wait: number = eventInfo.event.millisecondsTillStart;
      if (wait > 2100000000 ){
        wait = 2100000000 
      }
      timer = setTimeout(() => window.location.reload(), wait)
    }

    return () => {
      if (timer != null)
        clearTimeout(timer);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo,displayOptions]);
  return (
    <PageContainer>
      <Header match={match} collection={collection} selectedFontFamily={selectedFontFamily} />
      {/* <PlayerPageContents> */}
      { match.params.event === undefined ?
        <SpinnerOverlay>
          <h2 style={{color: 'white'}}>Jetstream Express&trade; is a powerful live captioning tool for delivering accessibility directly to your audience.<br />If you need further assistance regarding an event please contact us at <a style={{color: "#007EB5"}} href="mailto: jetstreamexpress@3playmedia.com">jetstreamexpress@3playmedia.com.</a></h2>
        </SpinnerOverlay> :
          ( eventInfo.eventStatus === EventLoadStatus.Empty || eventInfo.eventStatus === EventLoadStatus.Loading ? (
            <PlayerPageContents backgroundColor={'black'}>
              <WithSpinner />
            </PlayerPageContents>

          ) :
          ( eventInfo.eventStatus === EventLoadStatus.BadEventName ? 
            <PlayerPageContents backgroundColor={'black'}>
              <SpinnerOverlay><h2 style={{color: 'white'}}>Whoops, this link doesn't work. Check your event URL and try again.
                <br />If you need further assistance, please contact <a style={{color: "#007EB5"}} href="mailto: jetstreamexpress@3playmedia.com">jetstreamexpress@3playmedia.com</a></h2>
              </SpinnerOverlay>
            </PlayerPageContents> :
          ( eventInfo?.event?.displayTitle ?
              (sholdDisplayWelcomeMessage(eventInfo) ?
              <PlayerPageContents backgroundColor={'black'}>
                <SpinnerOverlay><h2 style={{color: 'white'}}>{eventInfo.event.welcomeMessage}</h2></SpinnerOverlay>
              </PlayerPageContents> :
                (
                  <>
                    <Title title={eventInfo?.event?.displayTitle} />
                    <PlayerPageContents backgroundColor={'white'}>
                      <iframe allowFullScreen frameBorder="0" title='StreamTextPlayer' id='streamTextIFrame' style={{ border:'0', width:'100%', height:'100%', overflow: 'hidden'}}></iframe>
                    </PlayerPageContents>
                  </>)) :
                  <PlayerPageContents backgroundColor={'black'}>
                    <SpinnerOverlay><h2 style={{color: 'white'}}>There was a problem loading the event information</h2></SpinnerOverlay>
                  </PlayerPageContents>
          ))
        )
      }
      {/* </PlayerPageContents> */}
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </PageContainer>

  );
 }

const mapStateToProps = (state : any) => ({
  displayOptions : state.displayOptions,
  eventInfo:  state.eventInfo 
  // selectedOptions: null
})

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  fetchEventInfo: (eventName: string) => dispatch(fetchEventInfo(dispatch)(eventName, ownProps))
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(PlayerPage));
