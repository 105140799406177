import styled from 'styled-components';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';

export interface SelectOptionCollectionStyleProps {
    isBurger: boolean;
}

export const SelectOptionItemContainer = styled.div<SelectOptionCollectionStyleProps>`
padding-bottom: ${p => p.isBurger ? '3px' : null};
margin-right: 3rem;
width: 14rem;
`

export const BackButton = styled(ButtonBack)`
  background-color: white;
  padding: 1px;
  margin: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: #242a3a;
  border: 2px solid gray;
  color: white;
  transition: 0.4s;
  &:hover {
    border: 3px solid aqua;
  }
`;
export const NextButton = styled(ButtonNext)`
  background-color: white;
  padding: 1px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-block;
  background-color: #242a3a;
  color: white;
  border: 2px solid gray;
  transition: 0.4s;
  &:hover {
    border: 3px solid aqua;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 15px;
  align-items: center;
  justify-content: center;
  background-color: rgb(26, 23, 23);
  margin-left: auto;
`;

export const SliderContainer = styled.div`
  position: relative;
  border: 0px;
  min-width: 50px;
  max-width: 700px;
`;