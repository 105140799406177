import React, {FunctionComponent} from 'react';
import { slide as Menu} from "react-burger-menu";

import SelectOptionCollection from '../selectOption/selectOptionCollection.component';


import {ExtendedBurgerProps } from '../../props/propTypes';


const MenuItems : FunctionComponent<ExtendedBurgerProps> = (props) => {
    const {collection, selectedFontFamily, ...otherProps} = props;
    
    return (
        <Menu {...otherProps}>
            <SelectOptionCollection collection={collection}
                selectedFontFamily={selectedFontFamily}
                isBurger={true} />
        </Menu>
    )
}

export default MenuItems;