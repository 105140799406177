import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './root-reducer';

const middlewares: Array<any> = [thunkMiddleware];

if (process.env.NODE_ENV === 'development')
    middlewares.push(logger);

export const store : any  = createStore(rootReducer, applyMiddleware(...middlewares));



