import DisplayActionTypes from "../display/display.types";
import EventActionTypes from "./event.types";

export const fectEventInfoStarted = () => ({
    type: EventActionTypes.LOAD_EVENT_DATA
});

export const fetchEventInfoSuccess = (eventInfo: any) => ({
    type: EventActionTypes.EVENTINFO_LOADED_SUCCESS,
    payload: eventInfo
});

export const fetchEventInfoFailure = (errorMsg: any) => ({
    type: EventActionTypes.EVENTINFO_LOADED_ERROR,
    payload: errorMsg
});

export const fetchEventInfoSetEventName = (eventName: string) => ({
    type: EventActionTypes.EVENTINFO_SET_EVENTNAME,
    payload: eventName
})

export const fetchEventInfoSetEventNotDoesNotExist = (eventName: string) => ({
    type: EventActionTypes.EVENTINFO_EVENT_NOTEXISTS,
    payload: eventName
})

export const fetchEventInfo = (dispatch: any) => (eventName: string, ownProps: any) => {
    if (ownProps.eventInfo?.eventName && ownProps.eventInfo?.eventName === eventName)
        return;
    else if (!ownProps.eventInfo?.eventName)
        dispatch(fetchEventInfoSetEventName(eventName))

    dispatch(fectEventInfoStarted());
    fetch(process.env.REACT_APP_GETAPILOCATIONURL)
        .then(response => {
            const urlResponse = response.json();
            return urlResponse;
        })
        .then(json =>{
            const request = new Request(`${json.url}/eventdisplay/${eventName}`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json'})
            });
            fetch(request)
            .then(response => {
                if (response.status === 404){
                    dispatch(fetchEventInfoSetEventNotDoesNotExist(eventName));
                    return undefined
                }
                else if (response.status !== 200)
                    throw new Error(response.statusText);
                var eventInfo = response.json();
                return eventInfo;
            })
            .then( returnInfo => {
                if (returnInfo){
                    dispatch({type: DisplayActionTypes.LOAD_LANGUAGE_OPTIONS, payload: { key: 'language', options: returnInfo.language }});
                    dispatch({type: DisplayActionTypes.CHANGE_SELECTED_FROM_EVENTLOAD, payload: {key: 'fontFamily', selectedOption: returnInfo.fontFamily}});
                    dispatch({type: DisplayActionTypes.CHANGE_SELECTED_FROM_EVENTLOAD, payload: {key: 'fontSize', selectedOption: returnInfo.fontSize}});
                    dispatch({type: DisplayActionTypes.CHANGE_SELECTED_FROM_EVENTLOAD, payload: {key: 'backColor', selectedOption: returnInfo.background}});
                    dispatch({type: DisplayActionTypes.CHANGE_SELECTED_FROM_EVENTLOAD, payload: {key: 'foreColor', selectedOption: returnInfo.textColor}});
                    dispatch(fetchEventInfoSuccess(returnInfo));
                }
            })
            .catch(error => fetchEventInfoFailure(error.message));
        });
    return {type: 'this is done'}
  };
