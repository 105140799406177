import { OptionType, HtmlSelectChange } from '../../props/propTypes';
import DisplayActionTypes from './display.types';

export const setSessionPageScroll = (pageScroll: boolean) => ({
    type: DisplayActionTypes.CHANGE_SESSION_SCROLLING,
    payload: pageScroll
});

export const ChangeSelectedItem = (selectedItem : OptionType) =>({
    type: DisplayActionTypes.CHANGE_SELECTED_VALUE,
    payload: selectedItem
})

export const ChangeSelectedItemHtmlSelect = (selectedItem : HtmlSelectChange) =>({
    type: DisplayActionTypes.CHANGE_SELECTED_VALUE_HTMLSELECT,
    payload: selectedItem
})

