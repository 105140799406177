import React from 'react';

import { SpinnerContainer, SpinnerOverlay } from './with-spinner.styles';

const WithSpinner = () => (
      <SpinnerOverlay>
        <SpinnerContainer />
      </SpinnerOverlay>
    );

export default WithSpinner;
